import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { graphql } from "gatsby";

import { ContentBlock, TextBlock } from "../../../components/Blocks";
import Pages from "../../../components/Pages";
import Trebs from "../../../components/Trebs";
import { makeUrl, removePreloader, translit, markdownConverter, removeEmptyKeys } from "../../../helpers";
import PropTypes from "prop-types";
import { Share } from "../../../widgets";
import * as TargetBanner from "../../../components/TargetBanner";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { useThemeContext } from "../../../components/Layout/ThemeContext";

export const query = graphql`
	query TrebsQuery($slug: String!) {
		hasura {
			...Banners

			text_pages(where: { slug: { _eq: $slug } }) {
				slug
				page_title_full
				main_image
				content_blocks
				seo
				template
			}
		}
	}
`;

export default function TrebsPage({ data }) {
	const { theme } = useThemeContext();

	const [textPages] = useState(get(data, "hasura.text_pages[0]", {}));
	const [banners] = useState(get(data, "hasura.banners", []));

	const url = makeUrl.textPage(textPages);

	const bannerBlock = banners.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(/trebs/)).map(banner => {
		return React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		});
	});
	const pageTitle = get(textPages, "page_title_full", "");
	const contentBlock = get(textPages, "content_blocks", "");

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages entity={textPages} url={url} >
			{!!bannerBlock.length && <ContentBlock key={"banner"}>
				<div className={"banner__main-block d-noscript-none"}>{bannerBlock}</div>
			</ContentBlock>}
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={pageTitle} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className="container">
					<div className={"h1 text-page_title"}>
						<h1 itemProp="headline"
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h1,
								color: theme.color.text,
							})}>
							{pageTitle}
						</h1>
					</div>
					{contentBlock &&
					contentBlock.map(({ title, body }, idx) => {
						return (
							<TextBlock
								key={`${translit(title)}-${idx}`}
								title={title}
								desc={markdownConverter(body)}
							/>
						);
					})}
					<Trebs />
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Требы"} />
			</div>
		</Pages>
	);
}

TrebsPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};
